import React from 'react'

import { useMediaQuery } from 'react-responsive'

import "../../styles/Nav.css"

const RDSTitle = (props) => {
  const isPhone = useMediaQuery({ 
    query: '(max-width: 550px)' 
  })
  const executeScroll = (id) => {
    id.current.scrollIntoView({behavior: 'smooth'});
    console.log("ref called: " + id);
  };

  return (
    <div className="navTitle" 
         style ={{"marginLeft": isPhone ? "0px": "25px"}}
         onClick={()=>{executeScroll(props.homeRef)}}>
        <div> 
            rice
            <span style={{"color": "#22577C"}}> data science</span>
        </div>
        
    </div>
  )
}

export default RDSTitle