import React from 'react'

import { useMediaQuery } from 'react-responsive'
import NavButton from "./NavButton.js"

import "../../styles/Nav.css"

const NavButtons = (props) => {
  const isPhone = useMediaQuery({ 
    query: '(max-width: 400px)' 
})

  return (
    <div className="buttons" style={{"marginLeft": isPhone ? "30px": "0px"}}>
        <div className="aboutButton" style={{"marginRight":isPhone ? "0px" : "23px"}}>
          <NavButton text="about" buttonRef={props.refs[1]}/>
        </div>
        <div className="eventButton">
          <NavButton text="events" buttonRef={props.refs[2]}/>
        </div>
        <NavButton text="team" buttonRef={props.refs[3]}/>
        <div className="joinButton">
          <NavButton text="join" buttonRef={props.refs[4]}/>
        </div>

    </div>
    

  )
}

export default NavButtons