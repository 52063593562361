import React from 'react'

// ALL STATIC WILL EVENTUALLY BE REPLACED WITH CONTENTFUL
import Jamie from "../../media/officers/jamie.jpg"
import Vinay from "../../media/officers/vinay.jpg"
import Davyd from "../../media/officers/davyd.jpg"
import Anthony from "../../media/officers/anthony.jpg"
import Yuka from "../../media/officers/yuka.JPG"
import Tina from "../../media/officers/tina.jpeg"
import Michael from "../../media/officers/michael.JPG"
import Gazi from "../../media/officers/gazi.jpg"
import Caleb from "../../media/officers/caleb.png"


import OfficerCard from "./OfficerCard"
import Header from "../shared/Header"

import "../../styles/Team.css"

const Team = (props) => {
    // TO BE REPLACED BY CONTENTFUL ONCE I GET ALL OFFICER INFO !!
    const staticOfficers =[
                        {
                            name: "Jamie Chen",
                            position: "President",
                            blurb: "Jamie is a senior at Hanszen studying Computational and Applied Math, Cognitive Science. She enjoys exploring new places, spending time with friends, and eating good food.",
                            email: "lc70@rice.edu",
                            image: Jamie

                        },
                        {
                            name: "Vinay Tummarakota",
                            position: "Internal Vice President",
                            blurb: "Vinay is a junior at Hanszen studying Computer Science. He beatboxes for Rice Philharmonics!",
                            email: "sst7@rice.edu",
                            image: Vinay
                        },
                        {
                            name: "Davyd Fridman",
                            position: "External Vice President",
                            blurb: "Davyd is a a junior majoring in Computer Science. He is interested in ML and Systems design. He plays rugby in my free time.",
                            email: "df21@rice.edu",
                            image: Davyd
                        },
                        {
                            name: "Anthony Yan",
                            position: "Treasurer",
                            blurb: "Anthony is a sophomore at Will Rice studying Computer Science and Data Science. He enjoys playing spikeball and exploring new places to eat.",
                            email: "ady2@rice.edu",
                            image: Anthony
                        },
                        {
                            name: "Yuka Aoyama",
                            position: "Socials",
                            blurb: "Yuka is a sophomore at Hanszen studying CS. She really enjoys learning and trying new stuff like screen printing, film photography, and new board/card games.",
                            email: "ya15@rice.edu",
                            image: Yuka
                        },
                        {
                            name: "Tina Wen",
                            position: "Socials",
                            blurb: "Tina is a sophomore at Wiess studying Computer Science and Business. She enjoys watching Formula 1 and running!",
                            email: "ttw4@rice.edu",
                            image: Tina
                        },
                        {
                            name: "Caleb Huang",
                            position: "Datathon Director",
                            blurb: "Caleb is a sophomore at Will Rice studying Social Policy Analysis. In his free time, he enjoys rock climbing, painting and taking hikes out in nature.",
                            email: "cdh7@rice.edu",
                            image: Caleb
                        },
                        {
                            name: "Gazi Fuad",
                            position: "Datathon Director",
                            blurb: "Gazi is a sophomore at Will Rice studying statistics & mathematical economics. Outside of Datathon planning, he serves as a PAA and Treasurer at Will Rice along with doing freelance photography on the side!",
                            email: "gff1@rice.edu",
                            image: Gazi
                        },
                        {
                            name: "Michael Kelley",
                            position: "Datathon Director",
                            blurb: "",
                            email: "mpk7@rice.edu",
                            image: Michael
                        }
                    ]
  return (
    <div className="team">
        <div className="teamHeader">
          <Header title="team" width={180} textSize={65}/>
        </div>
        <div className="cardContainer">
            {props.team && props.team.map(officer => 
                <OfficerCard name={officer.name}
                            position = {officer.position}
                            blurb = {officer.blurb}
                            email = {officer.email} 
                            image = {officer.photo}
                />
            )}
        </div>
        
    </div>
  )
}

export default Team