import React from 'react'

import { Slide, Fade } from "react-slideshow-image"
import 'react-slideshow-image/dist/styles.css';

import "../../styles/About.css"

const SlideShow = (props) => {
  props.images.map(item => console.log("pic: " + item.photo))
  console.log(props.images)
  return (
      <div className="slideShow" >
          <Slide cssClass="slideShow" easing="ease">
          {props.images.map((image, index) => (
          <div className="each-slide" key={index}>
            <div>
              <img src={image.photo} style={{ "width": "100%", "height": "100%" }}/>
            </div>
            {/* <h2>{fadeImage.caption}</h2> */}
          </div>
        ))}
        </Slide>
      </div>
      
  )
}

export default SlideShow