import React from 'react'

import { useMediaQuery } from 'react-responsive'

import AboutBlurb from "./AboutBlurb.js"
import SlideShow from "./SlideShow.js"

import "../../styles/About.css"

const AboutSection = (props) => {
    const isDesktop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isTablet = useMediaQuery({
        query: '(min-width: 700px)'
    })
    const isMobile = useMediaQuery({ 
        query: '(max-width: 700px)' 
    })

    const flexDirection = isMobile ? "column" : "row"
    const textAlign = isMobile ? "center" : "start"
    const widthPercentage = isMobile ? 65 : 40

  return (
    props.slidesFirst ? 
        <div className="aboutSection" style={{"flexDirection": flexDirection}}>
            <div className="slideShowContainer" style={{"width": `${widthPercentage}%`}}>
                <SlideShow images={props.images} />
            </div>
            <AboutBlurb title={props.title} blurb={props.blurb} widthPercentage={widthPercentage} textAlign={textAlign}/>
        </div> :
        <div className="aboutSection" style={{"flexDirection": flexDirection}}>
            <AboutBlurb title={props.title} blurb={props.blurb} widthPercentage={widthPercentage} textAlign={textAlign}/>
            <div className="slideShowContainer" style={{"width": `${widthPercentage}%`}}>
                <SlideShow images={props.images} />
            </div>
        </div>
  )
}

export default AboutSection