import React from 'react'

import "../../styles/Home.css"

const FirstHomeButton = (props) => {

  const executeScroll = (id) => {
    id.current.scrollIntoView({behavior: 'smooth', block:"start"});
    // console.log("ref called: " + id);
  };

  return (
    <div className="firstHomeButton" onClick={() => {executeScroll(props.moreRef)}}>
        {props.text}
    </div>
  )
}

export default FirstHomeButton