import React, { useState, useEffect } from 'react'

import Header from "../shared/Header"
import Program from "./Program"

import "../../styles/Join.css"

// NOTE: Listservs counts as a "program"
    //program is a way to get involved with RDS
const Join = (props) => {

  return (
    <div className="join">
        <div className="joinHeader">
            <Header title="join" width={135} textSize={65}/>
        </div>
        <div className="programContainer">
            {props.programs && props.programs.map((program, i) =>
                <Program blurb={program.blurb} 
                    buttonText={program.registrationButtonText}
                    open={program.registrationOpen}
                    link={program.registrationLink}
                    image={program.image}
                />
            )}
        </div>
    </div>
  )
}

export default Join