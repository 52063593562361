import React from 'react'

import { useMediaQuery } from 'react-responsive';

import "../../styles/Common.css"

import tiktokIcon from "../../media/icons/tiktok_icon.png"
import igIcon from "../../media/icons/ig_icon.png"
import fbIcon from "../../media/icons/fb_icon.png"


const IconBar = (props) => {
    const isPhone = useMediaQuery({ 
        query: '(max-width: 400px)' 
    })

    const fbLink = "https://www.facebook.com/ricedatasci/"
    const igLink = "https://www.instagram.com/ricedatasci/"
    const tiktokLink = "https://www.tiktok.com/@ricedatasci?_d=secCgYIASAHKAESPgo8HQI8KivfomkEAmFgknqGqwqWZuietFvtm2R4DT614pSr5AAvx8lawP0C8VTld3tlq1hjY3AdsPVpcbqsGgA%3D&_r=1&checksum=dd12285fc0f11971e9e0113433f00ac19b6b0151d19bcb9c1d7f985b345268e6&enable_clips=1&language=en&sec_uid=MS4wLjABAAAAfOz46DG1vnTOSzCthSxLXkbz67ocfPO-qypJEQonvDBG4cgW00NcD3PJpcGdAogr&sec_user_id=MS4wLjABAAAAT0zp_hP-N0ugEDkR49LIa_K4FS0ppYKR86qEIBNIHR9_FyLiRjAkKI1ZLToqV6oU&share_app_id=1233&share_author_id=7064071369269969967&share_link_id=25049C6E-F50F-4C52-B08B-2112A91A7510&source=h5_m&tt_from=sms&u_code=d97mcb9j5fj9a3&user_id=6757307885359858693&utm_campaign=client_share&utm_medium=ios&utm_source=sms"
  return (
    <div className="iconContainer" style={{"width": isPhone ? "20px": "35px", "height": isPhone ? "125px": "200px" }}>
        <img className="icon" src={fbIcon} onClick={(e) =>{window.open(fbLink, '_blank')}}/>
        <img className="icon" src={igIcon} onClick={(e) =>{window.open(igLink, '_blank')}}/>
        <img className="icon" src={tiktokIcon} onClick={(e) =>{window.open(tiktokLink, '_blank')}}/>
    </div>
  )
}

export default IconBar