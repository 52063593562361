import React from 'react'

import RegisterButton from "../shared/RegisterButton"

import "../../styles/Join.css"

const Program = (props) => {
  return (
    <div className="program">
        <img className="programImage" src={props.image} />
        <div className="programBlurb">{props.blurb}</div>
        <RegisterButton text={props.buttonText} url={props.link} open={props.open}/>
    </div>
  )
}

export default Program