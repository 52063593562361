import React, { useState, useEffect } from 'react'

import { COLORS } from "../../values/colors.js"

import { BasisCurve } from "react-svg-curve";
import { useMediaQuery } from 'react-responsive';

import Header from "../shared/Header.js"
import FirstHomeButton from "./FirstHomeButton.js"
import SecondHomeButton from "./SecondHomeButton.js"

import homePic from "../../media/rds_home_pic.png"

import "../../styles/Home.css"

const Home = (props) => {
    const isPC = useMediaQuery({
        query: '(min-width: 1600px)'
    })
    const isDesktop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isLargerTablet = useMediaQuery({
        query: '(min-width: 950px)'
    })
    const isTablet = useMediaQuery({
        query: '(min-width: 600px)'
    })
    const isMobile = useMediaQuery({ 
        query: '(min-width: 400px)' 
    })
    const isPhone = useMediaQuery({ 
        query: '(max-width: 400px)' 
    })

    const homeTitle = "Rice Students. Data Science"
    const homeBlurb = "driving a passion for data-driven thinking"

    var topCurveData = [];
    var midCurveData = [];
    var botCurveData = [];
    if (isMobile || isPhone){
        // console.log("switched to mobile")
        topCurveData = [[0, 175],
                        [300, 175],
                        [500, 275],
                        [700, 325],
                        [900, 275]];
        midCurveData = [[0,250],
                        [300,275],
                        [700,400]];
        botCurveData = [[0,350],
                        [800, 350]];
    }
    if (isTablet){
        // console.log("switched to tablet")
        topCurveData = [[0, 175],
                        [300, 175],
                        [500, 275],
                        [700, 325],
                        [900, 275],
                        [1100, 225],
                        [1250, 200]];
        midCurveData = [[0,250],
                        [300,275],
                        [700,400],
                        [1450,275]];
        botCurveData = [[0,350],
                        [800, 350],
                        [1224,350]];

    }
    if (isDesktop){
        // console.log("switched to desktop")
        topCurveData = [[0, 175],
                        [300, 175],
                        [500, 275],
                        [700, 325],
                        [900, 275],
                        [1100, 225],
                        [1250, 200],
                        [1350, 200],
                        [1450, 225]];
        midCurveData = [[0,250],
                        [300,275],
                        [700,400],
                        [1450,275]];
        botCurveData = [[0,350],
                        [800, 350],
                        [1224, 350], 
                        [1450,350]];
    }
    if (isPC){
        // console.log("switched to desktop")
        topCurveData = [[0, 175],
                        [300, 175],
                        [500, 275],
                        [700, 325],
                        [900, 275],
                        [1100, 225],
                        [1250, 200],
                        [1350, 200],
                        [1450, 225]];
        midCurveData = [[0,250],
                        [300,275],
                        [700,400],
                        [1450,275]];
        botCurveData = [[0,350],
                        [800, 350],
                        [1224, 350], 
                        [1450,350]];
    }
    const [buttonTopMargin, setButtonTopMargin] = useState(0)
    const adjustButtonMargin = () => {
        if (isDesktop && (isTablet || isMobile)){
            setButtonTopMargin(175)
        } else if (!isDesktop && isTablet && isMobile){
            setButtonTopMargin(225) //can't go further down
        } else if (isPhone){
            setButtonTopMargin(225)
        } else {
            setButtonTopMargin(225)
        }
    }
    useEffect(() => {
        adjustButtonMargin();
    },[window.innerWidth,isDesktop, isTablet, isMobile, isPhone]);
    

    return (
        <div className="home">
            {isLargerTablet ? <Header title={homeTitle} textSize={65} width={900}/> : 
                        <><Header title={"Rice Students."} textSize={isMobile || isPhone ? 45 : 65} width={isMobile || isPhone ? 320 : 465}/>
                        <Header title={"Data Science."} textSize={isMobile || isPhone ? 45 : 65} width={isMobile || isPhone? 300 : 375}/>
                        </>
            }
            
            <div className="waveContainer">
                {/* create the blue wave background */}
                <svg className="wave" width="100%" height="460">
                    <BasisCurve data={topCurveData} 
                                showPoints={false}
                                strokeWidth={125}
                                stroke={COLORS.lightBlue}
                    />

                    <BasisCurve data={midCurveData} 
                                showPoints={false}
                                strokeWidth={100}
                                stroke={COLORS.lightBlue}
                    />
                    <BasisCurve data={botCurveData} 
                                showPoints={false}
                                strokeWidth={195}
                                stroke={COLORS.lightBlue}
                    />
                </svg>
                {/* contains everything below header */}
                <div className="bottomHome">
                    {/* <div className="bottomLeftHome"> */}
                        <div className="homeBlurb" style={{"position": "absolute", 
                                                           "fontSize": ((isPhone || isMobile) && !isTablet) ? "25px": "35px",
                                                           "lineHeight": ((isPhone || isMobile) && !isTablet) ? "40px": "52.5px"}}>
                            {homeBlurb}
                        </div>
                        <div className="buttonContainer" style={{"position": "absolute", "marginTop": `${buttonTopMargin}px`}}> 
                            <FirstHomeButton text="read more" moreRef={props.moreRef}/>
                            <SecondHomeButton text="meet the team" teamRef={props.teamRef}/>
                        </div>
                    {/* </div> */}
                    <img src={homePic} className="homePic" />
                </div>
            </div>

        </div>
  )
}

export default Home