import React from 'react'

import { useMediaQuery } from 'react-responsive'

import Header from "../shared/Header"
import AboutSection from "./AboutSection"


import "../../styles/About.css"

const About = (props) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isTablet = useMediaQuery({
      query: '(min-width: 700px)'
  })
  const isMobile = useMediaQuery({ 
      query: '(max-width: 700px)' 
  })

    const whoTitle = "Who We Are"
    const whatTitle = "What We Do"

    const whoBlurb = <div className="aboutText">
                        Rice Data Science is a 
                        <span style={{"color": "#22577C"}}> student-led </span>
                        and
                        <span style={{"color": "#22577C"}}> student-centered </span>
                        organization that enables students to realize their passions and career aspirations in the field of data science.
                     </div>
    const whatBlurb = <div className="aboutText">
                          Our goal is to 
                          <span style={{"color": "#22577C"}}> create </span>
                          a collaborative, enriching, and interdisciplinary Data Science
                          <span style={{"color": "#22577C"}}> community </span>
                          at Rice. We coordinate career preparation opportunities, technical workshops, and social events to provide Rice students with the ability to develop greater proficiency in data science and engage with peers who share their passion for data science.
                      </div>

  return (
    <div className="about">
        <div className="aboutHeader">
          <Header title="about" textSize={65} width={210}/>
        </div>
        <AboutSection slidesFirst={true} 
                      title={whoTitle} 
                      blurb={whoBlurb} 
                      images={props.whoImages}
        />
        <AboutSection slidesFirst={isMobile ? true : false} 
                      title={whatTitle} 
                      blurb={whatBlurb} 
                      images={props.whatImages}
        />

    </div>
  )
}

export default About