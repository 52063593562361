import React from 'react'

import "../../styles/Team.css"

const OfficerCard = (props) => {
  return (
    <div className="officerCard">
        <img className="officerImage" src={props.image} />
        <div className="officerName">{props.name}</div>
        <div className="officerPosition"
             onClick={(e) =>{window.open("mailto:"+props.email, '_blank')}}>
             {props.position}</div>
        <div className="officerBlurb">{props.blurb}</div>
        {/* <div className="officerEmail" 
             onClick={(e) =>{window.open("mailto:"+props.email, '_blank')}}>
               {props.email}
        </div> */}

    </div>
  )
}

export default OfficerCard