import React from 'react'

import { COLORS } from "../../values/colors.js"

import "../../styles/Common.css"

const RegisterButton = (props) => {
  const backgroundColor = props.open ? COLORS.navy : "#808080"

  return (
    <div className="registerButton" 
         style={{"backgroundColor": backgroundColor, 
                 "borderColor": backgroundColor,
                 "width": `${props.widthPercentage}%`}}
         onClick={() => {props.open && window.open(props.url, '_blank')}}>
        {props.text}
    </div>
  )
}

export default RegisterButton