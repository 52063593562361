import React from 'react'

import "../../styles/Common.css"

const Header = (props) => {
    return (
        // <div style={{"width":`${props.width}px`}} className="headerContainer">
    <div style={{"width":`${props.width}px`, "fontSize": `${props.textSize}px`}} className="header">
                {props.title}
            </div>
        // </div>
    )
}

export default Header