import React, { useEffect, useState } from 'react'

import { send } from "emailjs-com"
import Alert from 'react-popup-alert'

import "../../styles/Contact.css"
import { useMediaQuery } from 'react-responsive'

const ContactForm = (props) => {
    const isPhone = useMediaQuery({ 
        query: '(max-width: 400px)' 
    })

    const SERVICE_ID = "service_u76mefe" //THIS SERVICE ID NOT WORKING CURRENTLY??
    const TEMPLATE_ID = "template_9al2v0a"
    //https://dashboard.emailjs.com/admin/integration
    const [toSend, setToSend] = useState({
        from_name: '',
        message: '',
        reply_to: '',
      });
    
    const onSubmit = (e) => {
        //NOTE: make fields required?
        console.log("send button pressed")
        e.preventDefault();

        if (toSend.from_name == "" || toSend.reply_to == "" || toSend.message == "" ){
            props.warningAction()
        } else {
            props.successAction()
            //comment to avoid sending unncessary emails during development
            //200 emails/month quota?? or 200 in general
            // send(
            //     SERVICE_ID,
            //     TEMPLATE_ID,
            //     toSend,
            //     'CW6vlU0RKD9RWihNW'
            // ).then((response) => {
            //     console.log('SUCCESS!', response.status, response.text);
            //   })
            //   .catch((err) => {
            //     console.log('FAILED...', err);
            //   });
        }

        //clear inputs
        setToSend({
            from_name: '',
            message: '',
            reply_to: '',
        })
    };

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };
    
  return (
    <form className="contactForm" style={{"marginTop": isPhone ? "30px": "0px"}}
            onSubmit={onSubmit}>
        <div className="contactHeader">contact</div>
        <div className="contactTitle">name</div>

        <input
            type='text'
            name='from_name'
            value={toSend.from_name}
            onChange={handleChange}
        />
        <div className="contactTitle">email</div>

        <input
            type='text'
            name='reply_to'
            value={toSend.reply_to}
            onChange={handleChange}
        />
        <div className="contactTitle">leave us a message!</div>
        <textarea
            type='text'
            name='message'
            value={toSend.message}
            onChange={handleChange}
        />
        <button className="contactSubmitButton" type='submit'>send</button>

    </form>
  )
}

export default ContactForm