import React from 'react'

import "../../styles/Home.css"

const SecondHomeButton = (props) => {
  const executeScroll = (id) => {
    id.current.scrollIntoView({behavior: 'smooth', block:"start"});
    // console.log("ref called: " + id);
  };

  return (
    <div className="secondHomeButton" onClick={() => {executeScroll(props.teamRef)}}>
        {props.text}
    </div>
  )
}

export default SecondHomeButton