import './App.css';
import { useState, useEffect, useRef } from "react"
import Nav from "./components/shared/Nav"
import Home from "./components/home/Home"
import About from "./components/about/About"
import Events from "./components/events/Events"
import Team from "./components/team/Team"
import Join from "./components/join/Join"
import Contact from "./components/contact/Contact"
import IconBar from "./components/shared/IconBar"

function App() {
  const homeRef = useRef(null);
	const aboutRef = useRef(null)
	const eventsRef = useRef(null)
	const teamRef = useRef(null)
	const joinRef = useRef(null)

  const refs = [homeRef, aboutRef, eventsRef, teamRef, joinRef]

  //CHANGE AND PASS TO EACH PAGE
  const [aboutWhoImages, setAboutWhoImages] = useState([])
  const [aboutWhatImages, setAboutWhatImages] = useState([])
  const [events, setEvents] = useState([])
  const [team, setTeam] = useState([])
  const [programs, setPrograms] = useState([])

  // Get about page who section image data
  useEffect(() =>{
    fetch("https://rdsbackend.herokuapp.com/about-who")
    .then(response => response.json())
    .then((data) => {
        setAboutWhoImages(data)
    }).catch(err => {
        console.log("APP TSX FETCH ERROR")
      });
    },[])
  // Get about section what section image data
  useEffect(() =>{
    fetch("https://rdsbackend.herokuapp.com/about-what")
    .then(response => response.json())
    .then((data) => {
        setAboutWhatImages(data)
    }).catch(err => {
        console.log("APP TSX FETCH ERROR")
      });
    },[])

  // Get event data
  useEffect(() =>{
        fetch("https://rdsbackend.herokuapp.com/events")
        .then(response => response.json())
        .then((data) => {
            setEvents(data)
        }).catch(err => {
            console.log("APP TSX FETCH ERROR")
          });
    },[])

    // Get team data
    //    .then((data) => {
    //   data.sort((a,b) => a.priority < b.priority ? 1 : -1)
    // })
  useEffect(() =>{
    fetch("https://rdsbackend.herokuapp.com/team")
    .then(response => response.json())
    .then((data) => {
        data.sort((a,b) => a.priority < b.priority ? -1 : 1)
        setTeam(data)
    }).catch(err => {
        console.log("APP TSX FETCH ERROR")
      });
    },[])

  // Get program data
  useEffect(() => {
    fetch("https://rdsbackend.herokuapp.com/programs").then(
      response => response.json()
    ).then((data) => { 
        setPrograms(data) 
      }
    ).catch(err => {
      console.log("APP TSX FETCH ERROR")
    });
  },[])

  return (
    <div>
      <div style={{"position": "fixed", "right": "20px","zIndex": "1"}}><IconBar /> </div>
      <div style={{"position": "fixed", "width": "100%", "zIndex": "1"}}><Nav refs={refs}/></div>
      <div ref={homeRef}><Home moreRef={aboutRef} teamRef={teamRef}/></div>
      <div ref={aboutRef}><About whoImages={aboutWhoImages} whatImages={aboutWhatImages}/></div>
      <div ref={eventsRef}><Events events={events}/></div>
      <div ref={teamRef}><Team team={team}/></div>
      <div ref={joinRef}><Join programs={programs}/></div>
      <Contact />
    </div>
  );
}

export default App;
