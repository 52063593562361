import React from 'react'

import "../../styles/About.css"

const AboutBlurb = (props) => {
  return (
    <div className="aboutBlurb" style={{"textAlign": props.textAlign, "width": `${props.widthPercentage}%`}}>
        <div className="aboutTitle"> {props.title}</div>
        {props.blurb}
    </div>
  )
}

export default AboutBlurb