import React, { useState } from 'react'

import { useMediaQuery } from 'react-responsive';
import Modal from 'react-modal';

import RegisterButton from '../shared/RegisterButton.js';
import ContactForm from "./ContactForm.js"

import { COLORS } from "../../values/colors.js"

import { BasisCurve } from "react-svg-curve";

import "../../styles/Contact.css"

const Contact = () => {
    const isDesktop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
    const isTablet = useMediaQuery({
        query: '(min-width: 900px)'
    })
    const isMobile = useMediaQuery({ 
        query: '(min-width: 400px)' 
    })
    const isPhone = useMediaQuery({ 
        query: '(max-width: 400px)' 
    })


    const mailingList = "https://mailman.rice.edu/mailman/listinfo/rice-datasci"
    const contactWaveDataUp = [
                            [0,50],
                            [5, 50],
                            [150, 125],
                            [325, 150],
                            [500, 145],
                            [700, 135],
                            [850, 100],
                            [1000, 75],
                            [1175, 60],
                            [1300, 75],
                            [1450, 125]
                        ]
    const contactWaveDataDown = [[0, 375], [1450, 375]]

    const flexDirection = isMobile ? "row" : "row"
    const height = isMobile ? 550 : 550
    const marginTop = isMobile ? 5 : 0;

    const successStyle = {
        content: {
          display: 'flex',
          flexDirection: 'column',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          width: '20%',
          height: '50px',
          textAlign: 'center',
          justifyContent: 'center',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '10px',
          borderWidth: '3px',
          borderStyle: 'solid',
        //   temporary, while emailjs is broken
          borderColor: COLORS.yellow,
          color: COLORS.yellow,
        //   borderColor: COLORS.navy,
        //   color: COLORS.navy,
          fontWeight: '800'
        },
      };
      const warningStyle = {
        content: {
          display: 'flex',
          flexDirection: 'column',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          width: '20%',
          height: '50px',
          textAlign: 'center',
          justifyContent: 'center',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '10px',
          borderWidth: '3px',
          borderStyle: 'solid',
          borderColor: COLORS.yellow,
          color: COLORS.yellow,
          fontWeight: '800'
        },
      };
    const [successOpen, setSuccessOpen] = useState(false)
    const [warningOpen, setWarningOpen] = useState(false)

    const showSuccessModal = () => {
        setSuccessOpen(true)
    }
    const showWarningModal = () => {
        setWarningOpen(true)
    }
    const closeSuccessModal = () => {
        setSuccessOpen(false)
    }
    const closeWarningModal = () => {
        setWarningOpen(false)
    }

  return (
    <div className="contact" style={{"height": `${height}px`}}>
        {/* Two modals, success or warning, to show on form submission */}
        <Modal
            isOpen={successOpen}
            onRequestClose={closeSuccessModal}
            style={successStyle}
            contentLabel="Success Modal"
        >
            {/* Message Sent! */} 
            {/* temporary, while emailjs is broken */}
            Unable to send message at this time
        </Modal>
        {/* show warning when message body is empty */}
        <Modal
            isOpen={warningOpen}
            onRequestClose={closeWarningModal}
            style={warningStyle}
            contentLabel="Warning Modal"
        >
            Please fill out all fields
        </Modal>
        <div className="contactWaveContainer">
            <svg className="contactWave" width="100%" height="600">
                        <BasisCurve data={contactWaveDataUp} 
                                    showPoints={false}
                                    strokeWidth={100}
                                    stroke={COLORS.lightBlue}
                        />
                        <BasisCurve data={contactWaveDataDown} 
                                    showPoints={false}
                                    strokeWidth={540}
                                    stroke={COLORS.lightBlue}
                        />
            </svg>
            <div className="contactContainer" style={{"flexDirection": flexDirection}}>
                <div className="listservSection" style={{"marginTop": `${marginTop}%`}}>
                    <div className="listservItems">
                        <div className="listservTitle">
                            join our listserv!
                        </div>
                        <RegisterButton text="subscribe" url={mailingList} open={true}/>
                    </div>
                </div>
                <div className="contactSection" style={{"marginTop": `${marginTop}%`, "marginLeft": isPhone ? "60px": "0px"}}>
                    {/* https://medium.com/geekculture/how-to-send-emails-from-a-form-in-react-emailjs-6cdd21bb4190 */}
                    <ContactForm successAction={showSuccessModal} warningAction={showWarningModal}/>
                </div>
            </div>
            
        </div>
        
    </div>
  )
}

export default Contact