import React from 'react'

import RegisterButton from '../shared/RegisterButton'

import "../../styles/Events.css"
import { COLORS } from '../../values/colors'

const EventBlock = (props) => {

  return (
    <div className="eventBlock" 
         style={{"marginLeft": `${props.marginLeft}px`,
                 "marginTop": props.onTimeline ? `${props.marginTop}px` : '50px',
                 "position": props.onTimeline ? 'absolute' : 'static'}}
    >
        <div className="eventTitle">{props.title}</div>
        <div className="eventTimeLocation"><span style={{"color": COLORS.navy, "fontWeight": 700}}>time:</span> {props.time}</div>
        <div className="eventTimeLocation"><span style={{"color": COLORS.navy, "fontWeight": 700}}>location:</span> {props.location}</div>
        <div className="eventBlurb">{props.blurb}</div>
        {props.buttonAvailable && <RegisterButton text={props.buttonName} 
                        url={props.buttonLink} 
                        open={true} 
                        widthPercentage={100}
        />}
    </div>
  )
}

export default EventBlock