import React, { useState, useEffect, useReducer } from 'react'

import { BasisCurve } from "react-svg-curve";
import { useMediaQuery } from "react-responsive"

import { COLORS } from "../../values/colors.js"

import Header from "../shared/Header.js"
import EventBlock from "./EventBlock.js"

import "../../styles/Events.css"

//TODOOO
//Add ternary operators in data paremeter for curve points
//adjust temp margins 

//NOTE: THERE SHOULD THERE ALWAYS BE 2-3 EVENTS
const Events = (props) => {
    const [events, setEvents] = useState([])
    const [numEvents, setNumEvents] = useState(0)
    const [eventPoints, setEventPoints] = useState([])
    const [eventMargins, setEventMargins] = useState([])
    const [addedTopMargin, setAddedTopMargin] = useState(0)
    const [pageLength, setPageLength] = useState(0)

    const isDesktop = useMediaQuery({
        query: '(min-width: 1250px)'
      })
    const isTablet = useMediaQuery({
        query: '(min-width: 875px)'
    })
    const isSmallerTablet = useMediaQuery({
        query: '(min-width: 625px)'
    })
    const isMobile = useMediaQuery({ 
        query: '(min-width: 400px)' 
    })
    const isPhone = useMediaQuery({ 
        query: '(max-width: 400px)' 
    })

    const timelineData = [[0,275],
                          [125, 200],
                          [300, 225],
                          [550, 275],
                          [675, 250],
                          [850, 175],
                          [1000, 75],
                          [1175, 0],
                          [1375, 75],
                          [1500, 200],
                        ]

    //FOR SCREEN COMPATABILITY --> ADJUST POINTS + MARGINS AS SCREEN WIDTH DECREASES
    const handlePageLength = () => {
        console.log("getting called")
        setPageLength((numEvents === 3) ? 1400 + ((!isSmallerTablet && (isMobile || isPhone)) ? 450 : 0) : 900 + ((!isSmallerTablet &&(isMobile || isPhone)) ? 400 : 0))
    }
    //add margin between timeline and event header in case there is an event above
    const handleTopMargin = () => {
        setAddedTopMargin(((isMobile || isPhone) && !isSmallerTablet) ? 50 : ((numEvents === 3) ? 400 : 0))

    }
    // USEEFFECT TO HANDLE SCREEN SIZE CHANGES
        // Adjusts event margins, event point positioning, page length based on screen size
    useEffect(() => {
        if (isPhone) {
            const handleMargins = () => (numEvents == 2) ? setEventMargins([[90,100],
                                [90,575]]) :
                                setEventMargins([[90,200],
                                [90,-210],
                                [90,740]])
            handleMargins()
            handlePageLength()
            handleTopMargin()
        }
        if (isMobile) {
            console.log("mobile!\n", numEvents)
            const handleMargins = () =>(numEvents == 2) ? setEventMargins([[180,100],
                                [180,575]]) : 
                                setEventMargins([[180,260],
                                [180,-210],
                                [180,740]])
            handleMargins()
            handlePageLength()
            handleTopMargin()
        }
        if (isSmallerTablet){
            console.log("smaller tablet", numEvents)
            // ith array represents info for ith event
                //for eventPoints, 1st and 2nd represent x and y 
                //for eventMargins, 1st and 2nd represent left and top margins
            const handlePoints = () => (numEvents == 2) ? setEventPoints([[125, 220],
                                [400, 245]]) : 
                                setEventPoints([[100, 225],
                                [300, 225],
                                [425, 250]])

            const handleMargins = () =>(numEvents == 2) ? setEventMargins([[110,260],
                                [385,275]]) :
                                setEventMargins([[80,260],
                                [280,-330],
                                [410,275]])
            handlePoints()
            handleMargins()
            handlePageLength()
            handleTopMargin()
        }
        if (isTablet){
            console.log("tablet!", numEvents)
            const handlePoints = () => (numEvents == 2) ? setEventPoints([[250, 220],
                                [600, 260]]) :
                                setEventPoints([[175, 213],
                                [400, 246],
                                [650, 250]])

            const handleMargins = () => (numEvents == 2) ? setEventMargins([[235,275],
                                [580,290]]) :
                                setEventMargins([[150,260],
                                [390,-330],
                                [635,280]])
            handlePoints()
            handleMargins()
            handlePageLength()
            handleTopMargin()
        }
        
        if (isDesktop){
            console.log("desktop!", numEvents)
            const handlePoints = () => (numEvents == 2) ? setEventPoints([[350, 235],
                                    [950, 110]]) :
                                setEventPoints([[250, 218],
                                    [550, 263],
                                    [1000, 80]])

            const handleMargins = () =>(numEvents == 2) ? setEventMargins([[330,275],
                                    [935,150]]) :
                                setEventMargins([[230,260],
                                    [535,-330],
                                    [985,120]])
            handlePoints()
            handleMargins()
            handlePageLength()
            handleTopMargin()
        }     
    }, [window.innerWidth, isMobile, isPhone, isSmallerTablet, numEvents])

    // determine 2 or 3 events ADD ONE SOON MAYBE FOUR??
    // useEffect(() =>{
    //     fetch("https://ricedatascibackend.herokuapp.com/events")
    //     .then(response => response.json())
    //     .then((data) => {
    //         setNumEvents(data.length)
    //         //sort events on timeline in chronological order
    //         data.sort((a,b) => (a.time > b.time) ? 1 : -1)
    //         setEvents(data)
    //     }).catch(err => {
    //         console.log("APP TSX FETCH ERROR")
    //       });
    // },[])
    useEffect(() =>{
        setNumEvents(props.events.length)
        //sort events on timeline in chronological order
        props.events.sort((a,b) => (a.time > b.time) ? 1 : -1)
        setEvents(props.events)
    },[props.events])

  return (
      //DESKTOP
      <div>
    {isDesktop && 
    <div className="events" style={{"height": `${pageLength}px`}}>
        <div className="eventsHeader">
            <Header title="events" width={240} textSize={65}/>
            <div className="timelineContainer" style={{"marginTop": `${addedTopMargin}px`}}>
                <svg className="timeline" width="100%" height="300">
                    <BasisCurve data={timelineData} 
                                showPoints={false}
                                strokeWidth={3}
                                strokeDasharray="20"
                    />
                    {/* eventually replace data with eventPoints (from useReducer) */}
                    {eventPoints && <BasisCurve data={eventPoints} 
                                showPoints={true}
                                strokeWidth={0}
                                pointElement={([x,y], i) => (
                                        <circle cx={x}
                                            cy={y}
                                            r={18}
                                            width="10"
                                            height="10"
                                            stroke={COLORS.navy}
                                            fill={COLORS.navy}
                                        />
                                )}        
                    />}
                </svg>
                {events && eventMargins && events.map((event, i) =>
                    <EventBlock title={event.name}
                                time={event.time}
                                location={event.location}
                                blurb={event.blurb}
                                buttonAvailable={event.buttonAvailable}
                                buttonName={event.registerButtonText}
                                buttonLink={event.registerLink}
                                marginLeft={eventMargins[i][0]}
                                marginTop={eventMargins[i][1]}
                                onTimeline={true}
                    />
                )}
            </div>
        </div>
    </div>}
    {/* TABLET */}
    {!isDesktop && isTablet && <div className="events" style={{"height": `${pageLength}px`}}>
    <div className="eventsHeader">
        <Header title="events" width={240} textSize={65}/>
        <div className="timelineContainer" style={{"marginTop": `${addedTopMargin}px`}}>
            <svg className="timeline" width="100%" height="300">
                <BasisCurve data={timelineData} 
                            showPoints={false}
                            strokeWidth={3}
                            strokeDasharray="20"
                />
                {/* eventually replace data with eventPoints (from useReducer) */}
                {eventPoints && <BasisCurve data={eventPoints} 
                            showPoints={true}
                            strokeWidth={0}
                            pointElement={([x,y], i) => (
                                    <circle cx={x}
                                        cy={y}
                                        r={18}
                                        // rx={150}
                                        // ry={150}
                                        width="10"
                                        height="10"
                                        stroke={COLORS.navy}
                                        fill={COLORS.navy}
                                    />
                            )}        
                />}
            </svg>
            {events && eventMargins && events.map((event, i) =>
                <EventBlock title={event.name}
                            time={event.time}
                            location={event.location}
                            blurb={event.blurb}
                            buttonAvailable={event.buttonAvailable}
                            buttonName={event.registerButtonText}
                            buttonLink={event.registerLink}
                            marginLeft={eventMargins[i][0]}
                            marginTop={eventMargins[i][1]}
                            onTimeline={true}
                />
            )}
            </div>
        </div>
    </div>}
    {!isTablet && isSmallerTablet && <div className="events" style={{"height": `${pageLength}px`}}>
    <div className="eventsHeader">
        <Header title="events" width={240} textSize={65}/>
        <div className="timelineContainer" style={{"marginTop": `${addedTopMargin}px`}}>
            <svg className="timeline" width="100%" height="300">
                <BasisCurve data={timelineData} 
                            showPoints={false}
                            strokeWidth={3}
                            strokeDasharray="20"
                />
                {/* eventually replace data with eventPoints (from useReducer) */}
                {eventPoints && <BasisCurve data={eventPoints} 
                            showPoints={true}
                            strokeWidth={0}
                            pointElement={([x,y], i) => (
                                    <circle cx={x}
                                        cy={y}
                                        r={18}
                                        width="10"
                                        height="10"
                                        stroke={COLORS.navy}
                                        fill={COLORS.navy}
                                    />
                            )}        
                />}
            </svg>
            {events && eventMargins && events.map((event, i) =>
                <EventBlock title={event.name}
                            time={event.time}
                            location={event.location}
                            blurb={event.blurb}
                            buttonAvailable={event.buttonAvailable}
                            buttonName={event.registerButtonText}
                            buttonLink={event.registerLink}
                            marginLeft={eventMargins[i][0]}
                            marginTop={eventMargins[i][1]}
                            onTimeline={true}
                />
            )}
            </div>
        </div>
    </div>}
    {/* MOBILE */}
    {!isSmallerTablet && (isMobile || isPhone) && <div className="events" style={{"height": `${pageLength}px`}}>
    <div className="eventsHeader">
        <Header title="events" width={240} textSize={65}/>
        <div className="mobileContainer" style={{"marginTop": `${addedTopMargin}px`,
                                                 "alignItems": "center"}}>
            {events && eventMargins && events.map((event, i) =>
                <EventBlock title={event.name}
                            time={event.time}
                            location={event.location}
                            blurb={event.blurb}
                            buttonAvailable={event.buttonAvailable}
                            buttonName={event.registerButtonText}
                            buttonLink={event.registerLink}
                            onTimeline={false}
                />
            )}
        </div>
    </div>
    </div>}
  
  </div>)
}

export default Events