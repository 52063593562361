import React from 'react'
import { useMediaQuery } from 'react-responsive'
import RDSTitle from "./RDSTitle.js"
import NavButtons from "./NavButtons.js"

import "../../styles/Nav.css"

const Nav = (props) => {
  const isPhone = useMediaQuery({ 
    query: '(max-width: 550px)' //at 550px, title not centered but join is not off screen
  })

  return (
    <div className="nav" style={{"flexDirection" : isPhone ? "column": "row",
                                "alignItems": "center"}}>
        <RDSTitle homeRef={props.refs[0]}/>
        <NavButtons refs={props.refs}/>
    </div>
  )
}

export default Nav