import React from 'react'

import "../../styles/Nav.css"

const NavButton = (props) => {
  const executeScroll = (id) => {
    id.current.scrollIntoView({behavior: 'smooth', block:"start"});
  };

  return (
    <div className="button" onClick={()=>{executeScroll(props.buttonRef)}}>
        {props.text}
    </div>
  )
}

export default NavButton